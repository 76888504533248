import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  Drawer,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  CreateHubdashLayout,
  CreateHubdashLayoutVariables,
  DeleteHubdashLayout,
  DeleteHubdashLayoutVariables,
  UpdateHubdashLayout,
  UpdateHubdashLayoutVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { QUERY } from 'src/components/HubDash/HubDashLayoutListCell/HubDashLayoutListCell'
import Button from 'src/components/Library/Button/Button'
import Switch from 'src/components/Library/Switch/Switch'
import useACL from 'src/lib/hooks/ACL/useACL'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useHubDashStore from 'src/lib/stores/hubDashStore'

export enum HubDashLayoutUserFilterType {
  ANY = 'ANY',
  ALL = 'ALL',
  CURRENT = 'CURRENT',
}

type LayoutFormErrors = {
  name: string
  dataFiltering: string
}

interface LayoutSettingsDrawerProps {
  layoutSettingsOpen: boolean
  setLayoutSettingsOpen: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const LayoutSettingsDrawer: FC<LayoutSettingsDrawerProps> = ({
  layoutSettingsOpen,
  setLayoutSettingsOpen,
  editMode,
  setEditMode,
}) => {
  const DEFAULT_LAYOUT_ERRORS: LayoutFormErrors = {
    name: '',
    dataFiltering: '',
  }

  // Form Errors
  const [formErrors, setFormErrors] = useState<LayoutFormErrors>(
    DEFAULT_LAYOUT_ERRORS,
  )

  const formFieldsInvalid = !!formErrors?.name || !!formErrors?.dataFiltering

  const [setLayout, setLayoutData, layoutData] = useHubDashStore((state) => [
    state.setLayout,
    state.setLayoutData,
    state.layoutData,
  ])

  const [enteredLayoutName, setEnteredLayoutName] = useState<string>(
    editMode ? layoutData?.name : '',
  )

  const [selectedUserFilter, setSelectedUserFilter] =
    useState<HubDashLayoutUserFilterType>(
      editMode
        ? (layoutData?.userFilterType as HubDashLayoutUserFilterType)
        : HubDashLayoutUserFilterType.ANY,
    )

  const [selectedOverride, setSelectedOverride] = useState<boolean>(
    editMode ? layoutData?.allowUserFilterOverride : false,
  )

  const userFilterOptions = [
    { value: HubDashLayoutUserFilterType.ANY, label: 'All Data & Users' },
    {
      value: HubDashLayoutUserFilterType.ALL,
      label: 'All Data & No User Filtering',
    },
    {
      value: HubDashLayoutUserFilterType.CURRENT,
      label: 'Current User Data Only',
    },
  ]

  const {
    AccessControlList,
    saveACL,
    resetACLComponent,
    isLoading: isLoadingACL,
  } = useACL({
    resourceId: editMode ? layoutData?.id : null,
    resourceType: 'HubDashLayout',
    principalTypes: ['MEMBERSHIP', 'MEMBERSHIPGROUP'],
  })

  const resetSettings = () => {
    setEnteredLayoutName('')
    setFormErrors({ name: '', dataFiltering: '' })
    setSelectedUserFilter(HubDashLayoutUserFilterType.ANY)
    setSelectedOverride(false)
    setLayout(null)
    setEditMode(false)
    resetACLComponent()
  }

  useEffect(() => {
    if (layoutSettingsOpen) {
      window.Intercom('update', {
        alignment: 'left',
      })
    } else {
      window.Intercom('update', {
        alignment: 'right',
      })
    }
  }, [layoutSettingsOpen])

  useEffect(() => {
    if (editMode) {
      setEnteredLayoutName(layoutData?.name)
      setSelectedUserFilter(
        layoutData?.userFilterType as HubDashLayoutUserFilterType,
      )
      setSelectedOverride(layoutData?.allowUserFilterOverride)
    } else {
      setEnteredLayoutName('')
      setSelectedUserFilter(HubDashLayoutUserFilterType.ANY)
      setSelectedOverride(false)
      setFormErrors({ name: '', dataFiltering: '' })
      resetACLComponent()
    }
  }, [editMode, layoutData, layoutSettingsOpen])

  const handleClose = () => {
    setLayoutSettingsOpen(false)
  }

  const CREATE_LAYOUT = gql`
    mutation CreateHubdashLayout($input: CreateHubDashLayoutInput!) {
      createHubDashLayout(input: $input) {
        id
      }
    }
  `
  const [createLayout, { loading: createLoading }] = useMutation<
    CreateHubdashLayout,
    CreateHubdashLayoutVariables
  >(CREATE_LAYOUT, {
    onCompleted: async ({ createHubDashLayout }) => {
      setLayoutSettingsOpen(false)
      setEnteredLayoutName('')
      setLayout(createHubDashLayout.id)
      // Save ACL Changes
      await saveACL({ resourceId: createHubDashLayout.id })
      // Route to page when done
      navigate(routes.hubDashWithId({ layoutId: createHubDashLayout.id }))
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
    refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const UPDATE_LAYOUT = gql`
    mutation UpdateHubdashLayout($id: Int!, $input: UpdateHubDashLayoutInput!) {
      updateHubDashLayout(id: $id, input: $input) {
        id
      }
    }
  `
  const [updateLayout, { loading: updateLoading }] = useMutation<
    UpdateHubdashLayout,
    UpdateHubdashLayoutVariables
  >(UPDATE_LAYOUT, {
    onCompleted: async ({ updateHubDashLayout }) => {
      setLayoutSettingsOpen(false)
      setEnteredLayoutName('')
      // Save ACL Changes
      await saveACL({ resourceId: updateHubDashLayout.id })
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
    refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const DELETE_LAYOUT = gql`
    mutation DeleteHubdashLayout($id: Int!) {
      deleteHubDashLayout(id: $id) {
        id
      }
    }
  `

  const [deleteLayout, { loading: deleteLoading }] = useMutation<
    DeleteHubdashLayout,
    DeleteHubdashLayoutVariables
  >(DELETE_LAYOUT, {
    onCompleted: () => {
      // Clear states
      resetSettings()
      setLayoutSettingsOpen(false)

      // Clear set layout data
      setLayoutData(null)
      // Reset page to automatically select top item
      navigate(routes.hubDash())
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
      })
    },
    refetchQueries: [{ query: QUERY, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  })

  const handleSave = () => {
    // Reset form state
    let formValid = true
    setFormErrors({ name: '', dataFiltering: '' })

    if (!enteredLayoutName) {
      setFormErrors({ ...formErrors, name: 'Layout must have a name.' })
      formValid = false
    }

    if (formValid) {
      if (editMode) {
        updateLayout({
          variables: {
            id: layoutData?.id,
            input: {
              name: enteredLayoutName,
              layoutData: {},
              userFilterType: selectedUserFilter,
              allowUserFilterOverride: selectedOverride,
            },
          },
        })
      } else {
        createLayout({
          variables: {
            input: {
              name: enteredLayoutName,
              layoutData: {},
              userFilterType: selectedUserFilter,
              allowUserFilterOverride: selectedOverride,
            },
          },
        })
      }
    }
  }

  const confirmDelete = useConfirm()

  useEffect(() => {
    if (enteredLayoutName) {
      setFormErrors({ ...formErrors, name: '' })
    }
  }, [enteredLayoutName])

  return (
    <Drawer
      anchor={'right'}
      open={layoutSettingsOpen}
      onClose={() => handleClose()}
      PaperProps={{
        style: {
          width: '600px',
        },
      }}
    >
      <div className="flex h-full w-full flex-col gap-6 overflow-auto p-4">
        <h1 className="text-2xl font-bold">
          {editMode ? 'Layout Settings' : 'Create Layout'}
        </h1>

        <FormControl fullWidth error={!!formErrors.name}>
          <TextField
            required
            fullWidth
            error={!!formErrors?.name}
            disabled={createLoading || updateLoading || deleteLoading}
            data-testid="layout-name-input"
            label="Layout Name"
            value={enteredLayoutName}
            onChange={(event) => setEnteredLayoutName(event.target.value)}
          />
          <FormHelperText>{formErrors.name}</FormHelperText>
        </FormControl>

        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <p>Data Filtering</p>
            <Select
              placeholder="Layout"
              value={selectedUserFilter}
              size="small"
              error={!!formErrors.dataFiltering}
              onChange={(event) =>
                setSelectedUserFilter(
                  event.target.value as HubDashLayoutUserFilterType,
                )
              }
              className="mb-1 w-72"
            >
              {userFilterOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <p className="text-sm text-gray-500">
            This setting will determine what data is shown in the layout and
            whether they can use user filtering.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <p>Allow Admin User Override?</p>
            <Switch
              checked={selectedOverride}
              onChange={(event) => setSelectedOverride(event.target.checked)}
            />
          </div>
          <p className="text-sm text-gray-500">
            This setting will allow admin users to override the data filtering
            settings.
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <p>Layout Access Control</p>
          <p className="text-sm text-gray-500">
            Layouts are public by default. To restrict access, you must select
            the members or groups you want to view this layout.{' '}
            <strong>
              All owners and admins have access to all layouts, regardless of
              this setting.
            </strong>
          </p>
        </div>
        <AccessControlList membershipCardTitle="Restrict Access" />

        <div className="mt-4 flex justify-between gap-2">
          <div>
            {editMode && (
              <Button
                color="error"
                onClick={() => {
                  confirmDelete({
                    title: 'Delete Layout',
                    description: 'Are you sure you want to delete this layout?',
                    confirmationText: 'Delete',
                    confirmationButtonProps: {
                      color: 'error',
                      className: 'bg-red-500',
                    },
                  }).then((isConfirmed) => {
                    if (!isConfirmed) return
                    deleteLayout({ variables: { id: layoutData?.id } })
                  })
                }}
                loading={deleteLoading}
                disabled={createLoading || updateLoading || isLoadingACL}
              >
                Delete
              </Button>
            )}
          </div>

          <div className="flex gap-2">
            <Button
              variant="text"
              onClick={() => handleClose()}
              disabled={createLoading || updateLoading || deleteLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSave()}
              buttonDataTestId={`layout-${editMode ? 'save' : 'create'}-button`}
              disabled={deleteLoading || formFieldsInvalid || isLoadingACL}
              loading={createLoading || updateLoading}
            >
              {editMode ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default LayoutSettingsDrawer
