import React, { Dispatch, FC, SetStateAction, useState } from 'react'

import {
  ArrowDownIcon,
  ArrowsPointingInIcon,
  ArrowUpIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
  TrashIcon,
  TvIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  LinkIcon,
  MagnifyingGlassIcon,
  StopIcon as StopIconSolid,
} from '@heroicons/react/24/solid'
import { Divider, Stack, Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useReactFlow } from 'reactflow'

import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import ReactMapDownloadButton from 'src/components/ReactMap/ReactMapDownloadButton'
import { CellMapInTask } from 'src/components/ReactMap/ReactMapEditorCell/ReactMapEditorCell'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { UseBooleanReturnType } from 'src/lib/hooks/UseBoolean'
import useReactFlowStore from 'src/lib/stores/reactMapStores'
import { useAuth } from 'src/Providers'

import type { LinkedResourceRow } from '../Library/LinkedContentBadge/LinkedContentBadge'

import ReactMapDeleteDescription from './ReactMapDeleteDescription'

const editRoles = ['ADMIN', 'OWNER', 'EDITOR', 'SUPERADMIN']

export interface ReactMapControlPanelUpperToolbarProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  saveMap: (mapId: number, refetch: boolean) => void
  mapName: string
  cancelSavingMap: () => void
  duplicateMap: () => void
  fullScreenMap: boolean
  setFullScreenMap: Dispatch<SetStateAction<boolean>>
  saveMapLoading: boolean
  deleteReactMapLoading: boolean
  deleteReactMap: () => void
  linkedWorkflowsRows: LinkedResourceRow[]
  duplicateMapLoading: boolean
  legendRef: React.RefObject<HTMLDivElement>
  settingsModal: UseBooleanReturnType
  mapInTasks: CellMapInTask
  isPublished: boolean
  publishMap: () => void
  publishingMapLoading: boolean
  deselectNodes: () => void
  undoAction: () => void
  redoAction: () => void
  mapId: number
  disableEditTools?: boolean
}

const ReactMapControlPanelUpperToolbar: FC<
  ReactMapControlPanelUpperToolbarProps
> = ({
  isEditing,
  setIsEditing,
  saveMap,
  mapName,
  cancelSavingMap,
  duplicateMap,
  fullScreenMap,
  setFullScreenMap,
  saveMapLoading,
  deleteReactMapLoading,
  deleteReactMap,
  linkedWorkflowsRows,
  duplicateMapLoading,
  legendRef,
  settingsModal,
  mapInTasks,
  isPublished,
  publishMap,
  publishingMapLoading,
  deselectNodes,
  undoAction,
  disableEditTools = false,
  // redoAction,
  mapId,
}) => {
  const { hasRole } = useAuth()
  const confirmDeleteReactMap = useConfirm()
  const reactFlow = useReactFlow()
  const setSearchText = useReactFlowStore((state) => state.setSearchText) // Function to set the state of searchText
  const { trackEvent } = useAnalytics()

  const [currentSearchText, setCurrentSearchText] = useState('')

  const updateSearch = () => {
    setSearchText(currentSearchText)
  }

  const history = useReactFlowStore((state) => state.history)

  return (
    <div className={'relative z-10 mx-auto flex justify-center p-10 pb-0 pt-4'}>
      <div className="flex flex-wrap items-center justify-between gap-4 rounded-full border border-gray-300 bg-white px-6 py-2">
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-3">
            <Stack
              direction={'row'}
              spacing={0}
              className={'flex flex-wrap items-center justify-center'}
              alignItems={'center'}
            >
              {/*Search Bar*/}
              <div className="flex items-center">
                <TextField
                  placeholder="Search Map"
                  name="searchText"
                  onChange={(e) => {
                    setCurrentSearchText(e.target.value)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      updateSearch()
                    }
                  }}
                  value={currentSearchText}
                  className="min-w-[200px] p-0"
                  size="small"
                />
                <Tooltip title={'Search Map'}>
                  <div>
                    <Button
                      fullWidth={false}
                      className="min-w-[0] rounded-full p-2 text-gray-500"
                      variant="text"
                      onClick={() => {
                        updateSearch()
                        trackEvent('Process Maps', 'click search map')
                      }}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <Divider orientation="vertical" flexItem className="my-3" />
              <div className="flex items-center">
                {/*Link & Download*/}
                {!disableEditTools && (
                  <>
                    <Tooltip title={'Copy Link To Share'}>
                      <div>
                        <Button
                          fullWidth={false}
                          className="min-w-[0] rounded-full p-2 text-gray-500"
                          variant="text"
                          onClick={() => {
                            const currentURL = window.location.href
                            navigator.clipboard
                              .writeText(currentURL)
                              .then(() => {
                                toast.success('Copied to clipboard')
                              })
                              .catch(() => {
                                toast.error('Failed to copy to clipboard')
                              })
                            trackEvent('Process Maps', 'copy url to clipboard')
                          }}
                        >
                          <LinkIcon className="h-5 w-5" />
                        </Button>
                      </div>
                    </Tooltip>
                    <ReactMapDownloadButton
                      mapName={mapName}
                      legendRef={legendRef}
                      deselectNodes={deselectNodes}
                      reactFlow={reactFlow}
                    />
                    <Divider orientation="vertical" flexItem className="my-3" />
                  </>
                )}
              </div>
              {/*View Options*/}
              <div className="flex items-center">
                <Tooltip title={'Fit chart to view'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="text"
                      className="min-w-[0] rounded-full p-2 text-gray-500"
                      onClick={() => {
                        reactFlow.fitView()
                        trackEvent('Process Maps', 'fit chart to view')
                      }}
                    >
                      <ArrowsPointingInIcon className="h-5 w-5" />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip title={'Zoom in'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="text"
                      className="min-w-[0] rounded-full p-2 text-gray-500"
                      onClick={() => {
                        reactFlow.zoomIn()
                        trackEvent('Process Maps', 'zoom in')
                      }}
                    >
                      <MagnifyingGlassPlusIcon className="h-5 w-5" />
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip title={'Zoom out'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="text"
                      className="min-w-[0] rounded-full p-2 text-gray-500"
                      onClick={() => {
                        reactFlow.zoomOut()
                        trackEvent('Process Maps', 'zoom out')
                      }}
                    >
                      <MagnifyingGlassMinusIcon className="h-5 w-5" />
                    </Button>
                  </div>
                </Tooltip>
                {!disableEditTools && (
                  <Tooltip
                    title={fullScreenMap ? 'Exit Full Screen' : 'Fullscreen'}
                  >
                    <div>
                      <Button
                        fullWidth={false}
                        variant="text"
                        className="min-w-[0] rounded-full p-2 text-gray-500"
                        onClick={() => {
                          setFullScreenMap(!fullScreenMap)
                          const eventName = `${
                            fullScreenMap ? 'exit' : 'enter'
                          } fullscreen`
                          trackEvent('Process Maps', eventName)
                        }}
                      >
                        {fullScreenMap ? (
                          <TvIcon className="h-5 w-5" />
                        ) : (
                          <TvIcon className="h-5 w-5" />
                        )}{' '}
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </div>
              <Divider orientation="vertical" flexItem className="my-3" />
              <div className="flex items-center">
                {hasRole(editRoles) && !disableEditTools && (
                  <>
                    {!isEditing && (
                      <Tooltip title={'Edit Map'}>
                        <div>
                          <Button
                            fullWidth={false}
                            variant="text"
                            className={
                              'min-w-[0] rounded-full p-2 text-gray-500'
                            }
                            onClick={() => {
                              setIsEditing(true)
                              trackEvent('Process Maps', 'edit map')
                            }}
                            loading={saveMapLoading}
                            buttonDataTestId="edit-map-button"
                          >
                            <PencilIcon className="h-5 w-5" />
                          </Button>
                        </div>
                      </Tooltip>
                    )}

                    {isEditing && (
                      <>
                        <Tooltip title={'Save Map'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="text"
                              className={
                                'min-w-[0] rounded-full p-2 text-green-600'
                              }
                              onClick={() => {
                                saveMap(mapId, true)
                                trackEvent('Process Maps', 'save map')
                              }}
                              loading={saveMapLoading}
                              buttonDataTestId="save-map-button"
                            >
                              <CheckIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </Tooltip>

                        <Tooltip title={'Stop Editing'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="text"
                              className="min-w-[0] rounded-full p-2 text-red-600"
                              onClick={cancelSavingMap}
                              loading={saveMapLoading}
                            >
                              <XMarkIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </Tooltip>

                        <Tooltip title={'Undo Action'}>
                          <div>
                            <Button
                              disabled={history?.undo?.length === 0}
                              fullWidth={false}
                              variant="text"
                              className="min-w-[0] rounded-full p-2 text-gray-500"
                              onClick={undoAction}
                            >
                              <ArrowUturnLeftIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </Tooltip>

                        {/*Commented out for now as buggy and not requested. If requested, uncomment and fix race condition issue*/}
                        {/*<Tooltip title={'Redo Action'}>*/}
                        {/*  <div>*/}
                        {/*    <Button*/}
                        {/*      disabled={history?.redo?.length === 0}*/}
                        {/*      fullWidth={false}*/}
                        {/*      variant="text"*/}
                        {/*      className="min-w-[0] p-2 rounded-full text-gray-500"*/}
                        {/*      onClick={redoAction}*/}
                        {/*    >*/}
                        {/*      <ArrowUturnRightIcon className="w-5 h-5" />*/}
                        {/*    </Button>*/}
                        {/*  </div>*/}
                        {/*</Tooltip>*/}

                        <Tooltip title={'Duplicate Map'}>
                          <div>
                            <Button
                              fullWidth={false}
                              variant="text"
                              className="min-w-[0] rounded-full p-2 text-gray-500"
                              onClick={duplicateMap}
                              loading={duplicateMapLoading}
                            >
                              <DocumentDuplicateIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </Tooltip>

                        <Tooltip
                          title={isPublished ? 'Convert To Draft' : 'Publish'}
                        >
                          <div data-intercom-target="publish-map-toggle">
                            <Button
                              fullWidth={false}
                              variant="text"
                              className="min-w-[0] rounded-full p-2 text-gray-500"
                              onClick={publishMap}
                              loading={publishingMapLoading}
                            >
                              {isPublished ? (
                                <ArrowDownIcon className="h-5 w-5" />
                              ) : (
                                <ArrowUpIcon className="h-5 w-5" />
                              )}
                            </Button>
                          </div>
                        </Tooltip>

                        <Tooltip title={'Delete Map'}>
                          <div>
                            <Button
                              fullWidth={false}
                              loading={deleteReactMapLoading}
                              className="min-w-[0] rounded-full p-2 text-red-500"
                              variant="text"
                              onClick={() =>
                                confirmDeleteReactMap({
                                  title: `Delete ${mapName}?`,
                                  description: (
                                    <ReactMapDeleteDescription
                                      mapInTasks={mapInTasks}
                                      linkedWorkflowsRows={linkedWorkflowsRows}
                                    />
                                  ),
                                  confirmationText: 'Delete',
                                  confirmationButtonProps: {
                                    color: 'error',
                                    className: 'bg-red-500',
                                  },
                                }).then((isConfirmed) => {
                                  if (!isConfirmed) return
                                  deleteReactMap()
                                })
                              }
                            >
                              <TrashIcon className="h-5 w-5" />
                            </Button>
                          </div>
                        </Tooltip>
                      </>
                    )}

                    {hasRole(['SUPERADMIN']) && !disableEditTools && (
                      <Tooltip title={'Super Admin Settings'}>
                        <div>
                          <Button
                            fullWidth={false}
                            variant="text"
                            className="min-w-[0] rounded-full p-2 text-gray-500"
                            onClick={settingsModal.toggle}
                          >
                            <Cog8ToothIcon className="h-5 w-5 text-gray-600" />
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip title={isPublished ? 'Published' : 'Draft'}>
                      <StopIconSolid
                        className={`text-${
                          isPublished ? 'green' : 'orange'
                        }-400 mx-2 h-4 w-4`}
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReactMapControlPanelUpperToolbar
